import { defineStore } from "pinia";

export const useCancelRequestStore = defineStore("cancelRequest", () => {
  let abortControllers: AbortController[] = [];

  function cancel(): void {
    abortControllers.forEach((abortController) => {
      abortController.abort();
    });
    abortControllers = [];
  }

  function getAbortController(): AbortController {
    const abortController = new AbortController();
    abortControllers.push(abortController);

    return abortController;
  }

  return {
    cancel,
    getAbortController,
  };
});
